import { Container, Typography } from '@mui/material'
import React from 'react'

function OurServices() {
  return (
    <>
    <Container><Typography variant='h1'>This is a Services Page</Typography></Container>
    </>
  )
}

export default OurServices
