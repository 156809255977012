import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button, Container, CardMedia } from "@mui/material";
import Heroimg from "../../Assets/img/building-exterior.webp";
// import Housekeeping from './../Housekeeping/Housekeeping';

const HeroSection = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      bgcolor: "var(--info-color)",
      pt: 4,
      pb: 4,
    }}
  >
    <Container maxWidth="sm">
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{ fontFamily: "var(--font-family-cardo)" }}
      >
        {/* A commitment to innovation and sustainability */}
        Empowering Hospitality professionals with state of the art AI
        assistance.
      </Typography>
      {/* <Typography variant="subtitle1" component="p" paragraph>
                Études is a pioneering firm that seamlessly merges creativity and functionality to redefine architectural excellence.
            </Typography> */}
      <Link to="/OurServices">
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "var(--black-color)" }}
        >
          Our Services
        </Button>
      </Link>
    </Container>
    <CardMedia
      component="img"
      image={Heroimg}
      alt="Hero Image"
      sx={{
        width: "80vw",
        maxHeight: "500px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
        mt: 4,
      }}
    />
    <Container maxWidth="sm" style={{ margin: "50px" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{ fontFamily: "var(--font-family-cardo)" }}
      >
        {/* A commitment to innovation and sustainability */}
        Streamline operations and enhance same to redefine excellence to ensure
        ultimate guest satisfaction.
      </Typography>
      {/* <Typography variant="subtitle1" component="p" paragraph>
                Études is a pioneering firm that seamlessly merges creativity and functionality to redefine architectural excellence.
            </Typography> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Link to="/Housekeeping">
          <Button
            variant="contained"
            size="large"
            style={{ backgroundColor: "var(--black-color)" }}
          >
            Housekeeping
          </Button>
        </Link>

        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "var(--black-color)" }}
        >
          Engeneering
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "var(--black-color)" }}
        >
          Food and beverage
        </Button>
      </Box>
    </Container>
  </Box>
);

export default HeroSection;
